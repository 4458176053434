/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Mono:200,300,400,500,600,700|IBM+Plex+Sans+Condensed:200,300,400,500,600,700|IBM+Plex+Sans:200,300,400,500,600,700&subset=latin-ext");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

::-moz-selection {
  color: #aaa;
  background: #fff;
}

::selection {
  color: #aaa;
  background: #fff;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 15px;
}

@media screen and (min-width: 768px) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 17px;
  }
}

@media screen and (min-width: 1680px) {
  html {
    font-size: 18px;
  }
}

body {
  color: #000;
  background-color: #fff;
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.25;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 700;
}

h1 a:hover {
  text-decoration: none;
}

h2 {
  font-weight: 700;
  margin-bottom: 1.25rem;
}

p, ul, ol {
  margin-bottom: 1.25rem;
}

p:last-child, ul:last-child, ol:last-child {
  margin-bottom: 0;
}

strong, b {
  font-weight: 700;
}

em, i {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
}

header {
  position: relative;
  width: calc(100% - 12px);
  margin-left: 6px;
  padding: 12px 0 0.5rem 0;
}

header > div {
  padding: 0 6px;
  margin-bottom: 1.25rem;
}

@media screen and (min-width: 768px) {
  header {
    display: flex;
    flex-flow: row nowrap;
  }
  header > div {
    flex: 0 0 25%;
  }
}

main {
  position: relative;
  height: auto;
  clear: both;
}

main.home-page {
  width: calc(100% - 24px);
  margin: 0 0 12px 12px;
}

main.project-page {
  width: calc(100% - 12px);
  margin: 0 0 12px 6px;
}

main.packery:after {
  content: ' ';
  display: block;
  clear: both;
}

main .gutter-sizer {
  width: 12px;
}

main .grid-sizer {
  width: calc(50% - 6px);
}

article {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 66.666vw;
  float: left;
  overflow: hidden;
  background-color: #000;
}

@media screen and (min-width: 768px) {
  article {
    width: calc(50% - 6px);
    height: 33.333vw;
  }
}

@media screen and (min-width: 1024px) {
  article {
    width: calc(25% - 9px);
    height: calc(16.666vw - 6px);
  }
}

@media screen and (min-width: 1024px) and (min-width: 1024px) {
  article.w2 {
    width: calc(50% - 6px);
    height: 33.333vw;
  }
}

article img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  transition: all 300ms;
}

article .description {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  color: #fff;
}

article .description h2 {
  display: block;
  padding: 12px;
  opacity: 0;
  transform: translateY(1em);
  transition: opacity 300ms, transform 0ms 300ms;
}

article:hover img {
  opacity: 0.35;
  filter: grayscale(1);
}

article:hover .description {
  opacity: 1;
}

article:hover .description h2 {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 600ms, transform 300ms;
}

main.project-page {
  display: flex;
  flex-flow: row wrap;
}

main.project-page .images {
  flex: 0 0 100%;
  padding: 0 6px;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 1024px) {
  main.project-page .images {
    flex: 0 0 50%;
  }
}

main.project-page .images img {
  width: 100%;
  height: auto;
}

main.project-page .images img + img {
  margin-top: 12px;
}

main.project-page .description, main.project-page .pagination {
  flex: 0 0 100%;
  padding: 0 6px;
  margin-bottom: 1.75rem;
}

@media screen and (min-width: 768px) {
  main.project-page .description, main.project-page .pagination {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 1024px) {
  main.project-page .description, main.project-page .pagination {
    flex: 0 0 25%;
  }
}

@media screen and (min-width: 1024px) {
  main.project-page .description h2 {
    display: block;
    padding-top: 1.5rem;
    border-top: 1px solid #000;
  }
}

@media screen and (min-width: 1024px) {
  main.project-page .pagination p:first-child {
    display: block;
    padding-top: 1.5rem;
    border-top: 1px solid #000;
  }
}

main.project-page .pagination a {
  text-decoration: none;
}

main.project-page .pagination a:hover span {
  text-decoration: underline;
}
