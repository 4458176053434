@import "_media-queries";
@import "_reset";

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:200,300,400,500,600,700|IBM+Plex+Sans+Condensed:200,300,400,500,600,700|IBM+Plex+Sans:200,300,400,500,600,700&subset=latin-ext');

$background: #fff;
$text: #000;
$gray: #aaa;

::-moz-selection {
	color: $gray;
	background: $background;
}

::selection {
	color: $gray; 
	background: $background;
}

* {
	box-sizing: border-box;
}

html {
	font-size: 15px;

	@include min-screen(768px) {
		font-size: 17px;
	}

	@include min-screen(1024px) {
		font-size: 16px;
	}

	@include min-screen(1440px) {
		font-size: 17px;
	}

	@include min-screen(1680px) {
		font-size: 18px;
	}
}

body {
	color: $text;
	background-color: $background;
	font-family: 'IBM Plex Mono', monospace;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.25;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1 {
	font-weight: 700;

	a:hover {
		text-decoration: none;
	}
}

h2 {
	font-weight: 700;
	margin-bottom: 1.25rem;
}

p, ul, ol {
	margin-bottom: 1.25rem;

	&:last-child {
		margin-bottom: 0;
	}
}

strong, b {
	font-weight: 700;
}

em, i {
	font-style: italic;
}

a {
	color: inherit;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

img {
	display: block;
}

// STRUCTURE

header {
	position: relative;
	width: calc(100% - 12px);
	margin-left: 6px;
	padding: 12px 0 0.5rem 0;

	& > div {
		padding: 0 6px;
		margin-bottom: 1.25rem;
	}

	@include min-screen(768px) {
		display: flex;
		flex-flow: row nowrap;

		& > div {
			flex: 0 0 25%;
		}
	}
}

main {
	position: relative;
	height: auto;
	clear: both;	

	&.home-page {
		width: calc(100% - 24px);
		margin: 0 0 12px 12px;
	}

	&.project-page {
		width: calc(100% - 12px);
		margin: 0 0 12px 6px;
	}

	&.packery:after {
		content: ' ';
		display: block;
		clear: both;
	}

	.gutter-sizer {
		width: 12px;
	}

	.grid-sizer {
		width: calc(50% - 6px);
	}
}

article {
	position: relative;
	padding: 0;
	margin: 0;
	width: 100%;
	height: 66.666vw;
	float: left;
	overflow: hidden;
	background-color: $text;

	@include min-screen(768px) {
		width: calc(50% - 6px);
		height: 33.333vw;
	}

	@include min-screen(1024px) {
		width: calc(25% - 9px);
		height: calc(16.666vw - 6px);

		&.w2 {
			@include min-screen(1024px) {
				width: calc(50% - 6px);
				height: 33.333vw;
			}
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
		opacity: 1;
		transition: all 300ms;
	}

	.description {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		color: $background;

		h2 {
			display: block;
			padding: 12px;
			opacity: 0;
			transform: translateY(1em);
			transition: opacity 300ms, transform 0ms 300ms;
		}
	}

	&:hover {
		img {
			opacity: 0.35;
			filter: grayscale(1);
		}

		.description {
			opacity: 1;

			h2 {
				opacity: 1;
				transform: translateY(0);
				transition: opacity 600ms, transform 300ms;
			}
		}
	}
}






main.project-page {
	display: flex;
	flex-flow: row wrap;

	.images {
		flex: 0 0 100%;
		padding: 0 6px;
		margin-bottom: 1.5rem;

		@include min-screen(1024px) {
			flex: 0 0 50%;
		}

		img {
			width: 100%;
			height: auto;

			& + img {
				margin-top: 12px;
			}
		}
	}

	.description, .pagination {
		flex: 0 0 100%;
		padding: 0 6px;
		margin-bottom: 1.75rem;

		@include min-screen(768px) {
			flex: 0 0 50%;
		}

		@include min-screen(1024px) {
			flex: 0 0 25%;
		}
	}

	.description {
		@include min-screen(1024px) {
			h2 {
				display: block;
				padding-top: 1.5rem;
				border-top: 1px solid $text;
			}
		}
	}

	.pagination {
		@include min-screen(1024px) {
			p:first-child {
				display: block;
				padding-top: 1.5rem;
				border-top: 1px solid $text;
			}
		}

		a {
			text-decoration: none;

			&:hover {
				span {
					text-decoration: underline;
				}
			}
		}	
	}
}
